import { MutatorDefs, WriteTransaction } from "@workcanvas/reflect";
import {
  addAttachedConnector,
  attachToFrame,
  changeElementProperties,
  changeElements,
  copyAttachedConnector,
  deleteElement,
  moveElement,
  moveElements,
  moveElementsByDelta,
  positionElement,
  putAttachedConnectorDragState,
  putElement,
  putElements,
  putAnys,
  removeAttachedConnector,
  removeMany,
  patchCanvasEl,
  patchAnything,
  getElement,
  sanityCheck
} from "./canvas-element";
import { initClientState, setCursor, setClientViewport, setPresent, setVideoPresence } from "./client-state";
import { placeConnectorPointAtIndex, updateConnectorOrientation, updateConnectorPointAtIndex } from "./connector";
import { drawLine, resizeDrawing } from "./drawing";
import { putAny, putMany, putManyWithSignal, deleteMany } from "./general";
import {
  changeMetadata,
  deleteMetadata,
  getThumbnailId,
  mergeMetadata,
  putManyMetadata,
  putMetadata,
  setThumbnailId,
  clearThumbnailId,
} from "./metadata";
import { resizeStickyNote } from "./sticky-note";
import { resizeTextBlock } from "./textBlock";
import {
  startVotingSession,
  endVotingSession,
  updateVotingStatus,
  vote,
  hideVotingSession,
  renameVotingSession,
} from "./voting";
import { putTimer } from "./timer";
import { updateIntegrationItemConfiguration } from "./integration-item";

export type M = typeof mutators;

export const mutators = {
  getElement,
  initClientState,
  setCursor,
  setClientViewport,
  setPresent,
  setVideoPresence,
  updateConnectorPointAtIndex,
  updateConnectorOrientation,
  placeConnectorPointAtIndex,
  resizeTextBlock,
  drawLine,
  resizeDrawing,
  resizeStickyNote,
  putElement,
  putElements,
  putAnys,
  removeMany,
  addAttachedConnector,
  removeAttachedConnector,
  copyAttachedConnector,
  putAttachedConnectorDragState,
  changeElementProperties,
  moveElement,
  moveElements,
  moveElementsByDelta,
  changeElements,
  attachToFrame,
  deleteElement,
  positionElement,

  putMetadata,
  putManyMetadata,
  changeMetadata,
  deleteMetadata,
  mergeMetadata,
  deleteMany,

  getThumbnailId,
  setThumbnailId,
  clearThumbnailId,

  putAny,
  putMany,
  putManyWithSignal,

  patchCanvasEl,
  patchAnything,

  startVotingSession,
  endVotingSession,
  vote,
  hideVotingSession,
  renameVotingSession,
  updateVotingStatus,

  updateIntegrationItemConfiguration,

  putTimer,

  sanityCheck,
  nop: async (_: WriteTransaction) => { },
};
